<template>
  <div class="row g-5 g-xxl-8 mx-0">
    <div class="card mb-5 mb-xl-10 py-2" id="kt_profile_details_view">
      <div class="card-header cursor-pointer px-0">
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">{{ $t("Company Details") }}</h3>
        </div>
      </div>
      <CompanyDetails
        v-if="businessData"
        :data="businessData"
      />
    </div>

<!--    <div class="card pt-3 p-0">-->
<!--      <div class="card-header cursor-pointer px-10">-->
<!--        <div class="card-title m-0">-->
<!--          <h3 class="fw-bolder m-0">{{ $t("Company Activity") }}-->
<!--          </h3>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="coming-soon-section">-->
<!--        {{ $t('Coming Soon') }}-->
<!--      </div>-->
<!--      <div class="d-flex align-items-center justify-content-between px-10 mt-7">-->
<!--        <p class="fs-4 fw-bold text-gray-600">{{ $t("“Questions replied monthly” by") }} Kolt Inc</p>-->
<!--        <p class="fs-4 fw-bold">10.021 {{ $t("questions replied") }}</p>-->
<!--      </div>-->
<!--      <BarChart />-->
<!--    </div>-->
  </div>
</template>
<script>
import CompanyDetails from "@/buying-teams/shared-components/settings/CompanyDetails";
import BarChart from "@/buying-teams/shared-components/charts/BarChart";
import BanksList from "@/buying-teams/shared-components/settings/BanksList";
import UsersList from "@/buying-teams/shared-components/settings/UsersList";
import AdminsList from "@/buying-teams/shared-components/settings/AdminsList";
import store from "@/store";

export default {
  name: "Overview",

  components: {
    CompanyDetails,
    BanksList,
    AdminsList,
    UsersList,
    BarChart
  },

  computed: {
    businessData() {
      const storeData = store.getters.business
      return [
        {
          label: this.$t('Company Name'),
          value: storeData.name ? storeData.name : '-'
        },
        {
          label: this.$t('Country of Global Headoffice'),
          value: storeData.country ? storeData.country : '-'
        },
        {
          label: this.$t('Industry'),
          value: storeData.industries ? storeData.industries.join(', ') : '-'
        },
        {
          label: this.$t('Global Number of Employees'),
          value: storeData.num_employees ? storeData.num_employees : '-'
        },
        {
          label: this.$t('Global Revenue'),
          value: storeData.revenue ? storeData.revenue + ' ' + this.$t('EUR') : '-'
        },
      ];
    },
    banksData() {
      return store.getters.businessBanks;
    }
  },
};
</script>
<style lang="scss" scoped>

</style>
